<template>
  <div class="page" :style="{ height: windowHeight + 'px' }">
    <div class="panel-card">
      <div class="panel-top">
        <img class="panel-title" src="./img/title.png" alt="基于区块链的生鲜农场品溯源平台">
      <transition name="fade">
        <keep-alive>
          <router-view class="view"></router-view>
        </keep-alive>
      </transition>
      </div>
      <!-- <p class="page-copyright">
        <span>广州蚁比特区块链科技有限公司</span>
        <br />
        <span>网站备案号：</span>
        <a href="http://www.beian.miit.gov.cn" target="_blank"
          >粤ICP备19159742号</a
        >
      </p> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['windowHeight']),
    marginTop() {
      const marginTop = (this.windowHeight - 600) / 2
      return marginTop > 20 ? marginTop : 20
    }
  }
}
</script>
<style lang="scss" scoped>
// 页面
.page {
  position: relative;
  height: 100vh;
  width: 100%;
  min-width: 768px;
  overflow: auto;
  background: url('~@/assets/login/bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  transition: height 0.2s;
}
.panel-title{
  display: block;
  width: 816px;
  margin: 14vh auto 0;
}
// 容器
.panel-card {
  position: relative;
  z-index: 1;
  width: 1000px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: margin 0.2s;
  // background: yellowgreen;
  display: flex;
  flex-direction: column;
  .panel-top{
    height: 95%;
    width: 1000px;
    height: calc(100%-50px);
    // background: aqua;
  }
  // transform: scale(0.67);
  // transform-origin: 50% 50%;
  // background: #fff;
  .page-copyright {
    // position: absolute;
    // bottom: 0;
    position: relative;
    top: 0;
    width: 100%;
    height: 50px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-top: -32px;
    // background: wheat;
    span,
    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .page {
    width: 100%;
    height: 100%;
    .page-copyright {
      display: none;
    }
  }
  .panel-card {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    &::v-deep {
      .panel-container {
        border-radius: 0 !important;
        .poster-container,
        .content-container {
          overflow: auto;
          width: 50%;
          border-radius: 0 !important;
        }
      }
      .tabs {
        height: 65px;
        padding: 0;
        .tab {
          width: 50%;
          text-align: center;
          margin-top: 0;
          border-radius: 0;
        }
      }
      .el-col {
        width: 100% !important;
      }
    }
  }
}

.view {
  position: absolute;
  width: 100%;
  height: 100%;
}

.fade-enter,
.fade-leave,
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  opacity: 0;
}
</style>
